import { Provider } from "components/hoc/quick_login/provider";
import { useUserCtx } from "components/hoc/user/provider";
import LoginConfirmator from "components/molecules/login_form_control/LoginConfirmator";
import LoginProvider from "components/organisms/login/LoginProvider";
import useChangeObserver from "hooks/change_observer/useChangeObserver";
import { type ReactNode, useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

type Then = () => void;
export interface QuickLoginContext {
  quickLogin: (then: Then) => void;
}

interface Props {
  children: ReactNode;
}

const QuickLoginProvider = ({ children }: Props): React.JSX.Element => {
  const [then, setThen] = useState<Then | undefined>();
  const { user } = useUserCtx();
  const { pathname } = useLocation();

  const quickLogin = useCallback((then: Then) => {
    console.info("requesting quick login");
    setThen(old => old ?? then);
  }, []);

  useChangeObserver(() => setThen(undefined), [user]); // unset login request if anything about user changes

  // this is needed so the quick login form will not appear after it was requested, but the user logged out and logged in again
  return (
    <Provider value={useMemo(() => ({ quickLogin }), [quickLogin])}>
      {children}

      {!!user && !!then && !pathname.includes("/login") && !pathname.includes("/logout") && (
        <LoginProvider>
          <LoginConfirmator
            onConfirmed={() => {
              setThen(undefined);
              then();
            }}
            onCancelled={() => {
              setThen(undefined);
            }}
          />
        </LoginProvider>
      )}
    </Provider>
  );
};

export default QuickLoginProvider;
