import { Button, IconButton } from "@panel2/systail-ui/button";
import { Menu, MenuButton } from "@panel2/systail-ui/menu";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { isLoginResponse, login as loginRequest } from "api/auth/login";
import { checkDomain, checkFullUsername, checkPass, checkUsername } from "util/validators";

import { Input } from "@panel2/systail-ui/form/input";
import { Switch } from "@panel2/systail-ui/form/switch";
import { Modal } from "@panel2/systail-ui/modal";
import { Spinner } from "@panel2/systail-ui/spinner";
import ArrowCircleRight from "components/atoms/icons/ArrowCircleRight";
import Lock from "components/atoms/icons/Lock";
import Logout from "components/atoms/icons/Logout";
import Person from "components/atoms/icons/Person";
import { useNotifierCtx } from "components/hoc/notifications/provider";
import { useSnackMessage } from "components/hoc/snack_message/provider";
import { useUserCtx } from "components/hoc/user/provider";
import { useLoginCtx } from "components/organisms/login/provider";
import type { Role } from "model/Permissions";
import { useSlug } from "provider";
import { notifyError } from "util/misc";

interface Props {
  onConfirmed: () => void;
  onCancelled: () => void;
}

const container = document.getElementById("quick-login");

const LoginConfirmator = ({ onConfirmed, onCancelled }: Props): React.JSX.Element => {
  const { login, logout } = useUserCtx();
  const notify = useNotifierCtx();
  const loginContext = useLoginCtx();
  const pushSnackMessage = useSnackMessage();
  const queryClient = useQueryClient();
  const slug = useSlug();

  const mutation = useMutation({
    mutationFn: loginRequest,
    onError: (error: Error) => {
      notifyError(notify, <span>{t`login.failed|Login Failed`}</span>, error);
    },
    onSuccess: data => {
      if (isLoginResponse(data, "internal") || isLoginResponse(data, "ldap")) {
        pushSnackMessage(t`login.success|Logged In`);
        queryClient.clear();

        login({
          username: data.email,
          email: data.email,
          id: data.id,
          tokens: [data.token, data.refresh, data.mercure],
          keepLogged: loginContext.keepLogged,
          isLdap: data.isLdap,
          companyId: data.company,
          companySlug: data.companySlug,
          passwordChangeRequired: data.passwordChangeRequired,
          roles: data.roles.reduce(
            (acc, role) => {
              acc[role] = true;

              return acc;
            },
            {} as Record<Role, boolean>,
          ),
        });
      } else {
        window.location.href = data.redirectUrl;
      }

      onConfirmed();
    },
  });

  const fullUsernameError = checkFullUsername(loginContext.usernameWithDomain);
  const usernameError = checkUsername(loginContext.username);
  const passError = checkPass(loginContext.pass);
  const domainError = loginContext.type === "ldap" && checkDomain(loginContext.ldap.domain);

  return (
    <Modal
      container={container ?? undefined}
      style={{ maxWidth: "90vw" }}
      className="bg-surface-high flex flex-col rounded p-3 text-on-surface"
      visible
      onVisibleChange={visible => !visible && onCancelled()}
    >
      <div className="mb-3 flex justify-between">
        <big className="my-auto flex flex-col text-2xl">
          <strong>
            <span>{t`labels.login_to_continue|Login to continue`}</span>
          </strong>

          <small className="text-dim">
            {loginContext.type === "ldap" ? (
              <span>{t`labels.ldap|LDAP`}</span>
            ) : (
              loginContext.type === "azure" && <span>{t`labels.azure|Microsoft`}</span>
            )}
          </small>
        </big>

        <IconButton
          tooltip={t`labels.go_to_standard_login|standard login page`}
          className="text-dim my-auto"
          onClick={() => logout()}
        >
          <Logout />
        </IconButton>
      </div>

      <form
        aria-label="login-form-control"
        onSubmit={e => {
          e.preventDefault(); // !do not reload

          if (loginContext.formOk) {
            mutation.mutate(
              loginContext.type === "ldap"
                ? {
                    type: loginContext.type,
                    username: loginContext.username ?? "",
                    password: loginContext.pass ?? "",
                    domain: loginContext.ldap.domain ?? "",
                    company: slug,
                  }
                : {
                    type: loginContext.type,
                    username: loginContext.usernameWithDomain ?? "",
                    password: loginContext.pass ?? "",
                    company: slug,
                  },
            );
          }
        }}
        className="flex flex-col"
      >
        <div className="mt-2 flex">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="my-auto mr-3" htmlFor="username-input">
            <Person size="1.2rem" className="text-primary" />
          </label>
          <Input
            data-cmp="login:form:username:input"
            aria-label="username"
            disabled={loginContext.locked || mutation.isPending}
            autoComplete="username"
            type="text"
            id="username-input"
            value={loginContext.usernameWithDomain ?? ""}
            onChange={({ target }) =>
              loginContext.setUsernameWithDomain(target.value.toLowerCase())
            }
            placeholder={t`placeholders.enter_username|Enter Username`}
          />
        </div>

        {!!fullUsernameError && (
          <strong data-cmp="login:form:username:error" className="text-rose-500">
            {t<{
              fullUsernameError: string;
            }>`labels.fullUserNameError|{fullUsernameError} ${{
              fullUsernameError,
            }}`}
          </strong>
        )}

        {loginContext.type === "ldap" && (
          <>
            {!!usernameError && (
              <strong data-cmp="login:form:username:error" className="text-rose-500">
                {t<{
                  usernameError: string;
                }>`labels.usernameError|{usernameError} ${{
                  usernameError,
                }}`}
              </strong>
            )}

            {!!domainError && (
              <strong data-cmp="login:form:domain:error" className="text-rose-500">
                {t<{
                  domainError: string;
                }>`labels.domainError|{domainError} ${{
                  domainError,
                }}`}
              </strong>
            )}
          </>
        )}

        {loginContext.type !== "azure" && (
          <div className="mt-2 flex">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="my-auto mr-3" htmlFor="password-input">
              <Lock size="1.2rem" className="text-primary" />
            </label>
            <Input
              data-cmp="login:form:password:input"
              aria-label="password"
              disabled={loginContext.locked || mutation.isPending}
              autoComplete="current-password"
              type="password"
              id="password-input"
              value={loginContext.pass ?? ""}
              onChange={({ target }) => loginContext.setPass(target.value)}
              placeholder={t`placeholders.enter_pass|Enter Password`}
            />
          </div>
        )}

        {!!passError && (
          <strong data-cmp="login:form:password:error" className="text-rose-500">
            {t<{
              passError: string;
            }>`labels.passError|{passError} ${{
              passError,
            }}`}
          </strong>
        )}

        <div className="my-3">
          <Switch
            data-cmp="login:form:keep-logged:check"
            aria-label="keep-logged"
            onChange={() => loginContext.setKeepLogged(o => !o)}
            checked={loginContext.keepLogged}
            disabled={loginContext.locked || mutation.isPending}
            labelAfter={<span>{t`labels.keep_me_logged|keep me logged`}</span>}
          />
        </div>

        <div className="flex justify-between">
          <small className="my-auto mr-3">
            {loginContext.type !== "internal" ? (
              <Button
                onClick={() => loginContext.setType("internal")}
                data-cmp="back-to-normal-login-btn"
                className="text-dim"
              >
                <span>{t`labels.default_login|back to normal login`}</span>
              </Button>
            ) : (
              <Menu
                trigger={
                  <Button data-cmp="login:form:other-login:dropdown">
                    <span>{t`labels.other_login_options|other login options`}</span>
                  </Button>
                }
              >
                <MenuButton
                  onClick={() => loginContext.setType("ldap")}
                  data-cmp="login:form:other-login:dropdown:ldap-button"
                >
                  <span>{t`labels.ldap_login|LDAP login`}</span>
                </MenuButton>
                <MenuButton
                  onClick={() => loginContext.setType("azure")}
                  data-cmp="login:form:other-login:dropdown:azure-button"
                >
                  <span>{t`labels.azure_login|Microsoft login`}</span>
                </MenuButton>
              </Menu>
            )}
          </small>

          <div className="mt-auto flex">
            <Button className="mr-2" onClick={onCancelled}>
              <span>{t`labels.cancel|Cancel`}</span>
            </Button>

            <Button
              data-cmp="login:form:submit:button"
              aria-label="submit"
              type="submit"
              disabled={!loginContext.formOk}
              className="flex"
              $variant="secondary"
            >
              {loginContext.type === "azure" ? (
                <span>{t`labels.next|Next`}</span>
              ) : (
                <span>{t`labels.submit|Submit`}</span>
              )}
              {mutation.isPending ? (
                <Spinner $variant="tertiary" className="my-auto ml-1 flex" />
              ) : (
                <ArrowCircleRight size="1rem" className="my-auto ml-1" />
              )}
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default LoginConfirmator;
