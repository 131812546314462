import { type QueryParams, queryParamsToSearchParams, searchParamsToQueryParams } from "api/utils";
import { Provider } from "components/hoc/query/provider";
import { type Dispatch, type ReactNode, type SetStateAction, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

interface Props {
  children: ReactNode;
}

const QueryStateContextProvider = ({ children }: Props): React.JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();

  const state = useMemo(() => {
    const params = searchParamsToQueryParams(searchParams);

    return [
      params,
      next => {
        setSearchParams(prev => {
          const nextParams =
            typeof next === "function" ? next(searchParamsToQueryParams(prev)) : next;

          return queryParamsToSearchParams(nextParams);
        });
      },
    ] as [QueryParams, Dispatch<SetStateAction<QueryParams>>];
  }, [searchParams, setSearchParams]);

  return <Provider value={state}>{children}</Provider>;
};

export default QueryStateContextProvider;
