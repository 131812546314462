import { Dialog } from "@panel2/systail-ui/dialog";
import { Provider } from "components/hoc/confirmation/provider";
import { useSnackMessage } from "components/hoc/snack_message/provider";
import {
  type CSSProperties,
  type ComponentType,
  type ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";
export interface ConfirmationDialog {
  contents: React.JSX.Element | string | string[];
  onConfirm: () => void;
  onCancel?: () => void;
  confirmButtonText?: string;
  cancelButtonText?: string;
  doubleConfirm?: boolean;
  style?: CSSProperties;
}

interface ConfirmatorProps {
  close: () => void;
}

type Confirmator<T extends ConfirmatorProps = ConfirmatorProps> =
  ComponentType<T>;
type ConfirmationDialogItem = ConfirmationDialog | Confirmator;
export interface ConfirmationContext {
  confirm: (context: ConfirmationDialogItem, last?: number) => number;
  activeCount: number;
}

interface Props {
  children: ReactNode;
}

let lastId = 0;
const portalContainer = document.getElementById("confirm") || undefined;

const isDialog = (
  dialog: ConfirmationDialogItem
): dialog is ConfirmationDialog =>
  typeof dialog === "object" &&
  Object.prototype.hasOwnProperty.call(dialog, "contents");

const ConfirmationProvider = ({ children }: Props): React.JSX.Element => {
  const push = useSnackMessage();

  const [openDialogs, setOpenDialogs] = useState<
    Record<
      number,
      {
        id: number;
        dialog: ConfirmationDialogItem;
      }
    >
  >([]);

  const confirm = useCallback(
    (dialog: ConfirmationDialogItem, last?: number): number => {
      const id = last ?? ++lastId;

      setOpenDialogs((old) => ({
        ...old,
        [id]: { dialog, id },
      }));

      return id;
    },
    []
  );

  const close = useCallback(
    (id: number) =>
      setOpenDialogs((old) => {
        const cp = { ...old };
        delete cp[id];

        return cp;
      }),
    []
  );

  const activeCount = useMemo(
    () => Object.keys(openDialogs).length,
    [openDialogs]
  );

  const value = useMemo<ConfirmationContext>(
    () => ({ confirm, activeCount }),
    [confirm, activeCount]
  );

  return (
    <>
      <Provider value={value}>{children}</Provider>
      {Object.values(openDialogs).map(({ dialog, id }, i) => {
        if (isDialog(dialog)) {
          return (
            <Dialog
              portalContainer={portalContainer}
              key={`confirm-dialog-${id}-${i}`}
              aria-label="confirm-box"
              acceptText={dialog.confirmButtonText ?? t`labels.accept|Accept`}
              rejectText={dialog.cancelButtonText ?? t`labels.reject|Reject`}
              doubleConfirm={dialog.doubleConfirm}
              doubleConfirmOnSingleClick={() =>
                push(t`labels.double_click_to_confirm|Double click to confirm`)
              }
              onReject={() => {
                if (dialog.onCancel) dialog.onCancel();

                close(id);
              }}
              onAccept={() => {
                dialog.onConfirm();
                close(id);
              }}
              style={dialog.style}
            >
              {dialog.contents || t`labels.are_you_sure|Are you sure?`}
            </Dialog>
          );
        }
        const Confirmator = dialog;

        return (
          <Confirmator
            key={`confirm-dialog-${id}-${i}`}
            close={() => close(id)}
          />
        );
      })}
    </>
  );
};

export default ConfirmationProvider;
