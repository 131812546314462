import { Button } from "@panel2/systail-ui/button";
import SingleNotification from "components/atoms/notification/Notification";
import { useHideNotification, useNotificationsCtx } from "components/hoc/notifications/provider";
import type Notification from "model/Notification";
import { createPortal } from "react-dom";
import { MAX_NOTIFICATIONS_STACKED } from "util/const";
const element = document.getElementById("notifications");
const sorter = (a: Notification, b: Notification) => a.createdAt.getTime() - b.createdAt.getTime();

const Notifications = (): React.JSX.Element => {
  const notifications = useNotificationsCtx();
  const hide = useHideNotification();

  const content = (
    <div className="m-2 flex flex-col">
      {notifications &&
        notifications.length > 0 &&
        notifications
          .sort(sorter)
          .slice(0, MAX_NOTIFICATIONS_STACKED)
          .map(notification => (
            <SingleNotification
              key={notification.id}
              onClose={() => hide(notification.id)}
              notification={notification}
            />
          ))}
      {notifications && notifications.length > MAX_NOTIFICATIONS_STACKED && (
        <div className="delayed-grow mx-3 mb-2 flex rounded bg-surface-container px-3 py-1">
          <strong className="m-auto">
            <span>{t`labels.more_dots|...`}</span>
          </strong>
        </div>
      )}
      {notifications && notifications.length >= 3 && (
        <Button
          onClick={() => hide("all")}
          $variant="secondary"
          className="delayed-grow-sm mx-3 mb-2 grow overflow-hidden rounded border-none"
        >
          <strong className="m-auto">
            <span>{t`labels.clear|Clear`}</span>
          </strong>
        </Button>
      )}
    </div>
  );

  return element ? createPortal(content, element) : content;
};

export default Notifications;
