import { Button } from "@panel2/systail-ui/button";
import { useSlug } from "provider";
import { useNavigate } from "react-router-dom";

const ResetInvalidForm = (): React.JSX.Element => {
  const navigate = useNavigate();
  const slug = useSlug();

  return (
    <div className="delayed-grow m-auto flex flex-col lg:ml-auto">
      <div className="rounded-t border-x-4 border-t-4 border-surface-variant bg-surface px-5 pb-3 pt-5 drop-shadow-xl">
        <h2 className="my-4 select-none text-4xl text-on-surface">
          <strong>
            <span>{t`labels.error|Error`}</span>
          </strong>
        </h2>
      </div>
      <div className="m-0 h-1" />
      <div className="flex flex-col justify-center rounded-b border-x-4 border-b-4 border-surface-variant bg-surface px-4 pb-3 pt-4 text-xl drop-shadow-xl">
        <div className="m-auto mb-4">{t`labels.your_token_is_invalid|Your token is invalid`},</div>
        <div className="m-auto">
          {t`labels.reset_token_info|Please check your token, generate new one or contact administrator for further information`}
        </div>
        <div className="mt-4 flex justify-center">
          <Button
            className="mt-auto flex"
            $variant="primary"
            onClick={() => navigate(`/${slug}/login`)}
          >
            <span className="my-auto">{t`labels.reset_invalid_form_go_to_login|Login`}</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ResetInvalidForm;
