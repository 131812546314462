import type { QueryKey } from "@tanstack/react-query";

const trimRightUndefined = (arr: (unknown | undefined)[]) => {
  const next = [...arr];

  for (let i = arr.length - 1; i >= 0; i--) {
    if (arr[i] !== undefined) {
      break;
    }

    next.pop();
  }

  return next;
};

export type DictionaryQueryKeyNamespace =
  | "location"
  | "screen"
  | "company"
  | "user"
  | "schedule"
  | "repository"
  | "layout"
  | "custom_field"
  | "tag"
  | "language"
  | "debug"
  | "orientation"
  | "timezone"
  | "software"
  | "firmware"
  | "sync_play"
  | "ntp";
export type ListQueryKeyNamespace =
  | `${"ldap" | "azure" | "ad"}Configurations`
  | `${"ldap" | "azure" | "ad"}AvailableGroups`
  | "location"
  | "screen"
  | "company"
  | "user"
  | "schedule"
  | "repository"
  | "layout"
  | "custom_field"
  | "tag"
  | "connection_token"
  | "connections"
  | "connections_apps"
  | "custom_field_screen"
  | "custom_field_location"
  | "screen_logs_types"
  | "screen_logs"
  | "system_logs_types"
  | "system_logs";
export type ItemQueryKeyNamespace =
  | "location"
  | "screen"
  | "company"
  | "user"
  | "schedule"
  | "repository"
  | "layout"
  | "custom_field"
  | "tag"
  | "catalog"
  | "playlist"
  | "authUrl";

export const isDictionaryQueryKey = (
  key:
    | DictionaryQueryKeyNamespace
    | ListQueryKeyNamespace
    | ItemQueryKeyNamespace
): key is DictionaryQueryKeyNamespace => {
  return [
    "location",
    "screen",
    "company",
    "user",
    "schedule",
    "repository",
    "layout",
    "custom_field",
    "tag",
    "language",
    "debug",
    "orientation",
    "timezone",
    "software",
    "firmware",
    "ntp",
    "sync_play",
    "authUrl",
  ].includes(key);
};

export const isListQueryKey = (
  key:
    | DictionaryQueryKeyNamespace
    | ListQueryKeyNamespace
    | ItemQueryKeyNamespace
): key is ListQueryKeyNamespace => {
  return [
    "location",
    "screen",
    "company",
    "user",
    "schedule",
    "repository",
    "layout",
    "custom_field",
    "tag",
    "connections",
    "connections_apps",
    "custom_field_screen",
    "custom_field_location",
    "screen_logs_types",
    "screen_logs",
    "system_logs_types",
    "system_logs",
    "ldapConfigurations",
    "azureConfigurations",
  ].includes(key);
};

export const isItemQueryKey = (
  key:
    | DictionaryQueryKeyNamespace
    | ListQueryKeyNamespace
    | ItemQueryKeyNamespace
): key is ItemQueryKeyNamespace => {
  return [
    "location",
    "screen",
    "company",
    "user",
    "schedule",
    "repository",
    "layout",
    "custom_field",
    "tag",
    "catalog",
    "playlist",
    "authUrl",
  ].includes(key);
};

export const getListQueryKey = (
  namespace: ListQueryKeyNamespace,
  params?: unknown
): QueryKey => trimRightUndefined([namespace, "list", params]);
export const getItemQueryKey = (
  namespace: ItemQueryKeyNamespace,
  targetId?: string,
  params?: unknown
): QueryKey => trimRightUndefined([namespace, "item", targetId, params]);
export const getTreeQueryKey = (
  namespace: ItemQueryKeyNamespace,
  targetId?: string,
  params?: unknown
): QueryKey => trimRightUndefined([namespace, "tree", targetId, params]);
export const getPermissionsQueryKey = (userId?: string): QueryKey =>
  trimRightUndefined(["permissions", userId]);
export const getUserSettingsQueryKey = (userId?: string): QueryKey =>
  trimRightUndefined(["user-settings", userId]);
export const getCompanySettingsQueryKey = (userId?: string): QueryKey =>
  trimRightUndefined(["company-settings", userId]);
export const getReleaseNotesQueryKey = (
  namespace?: "front" | "back",
  homepage?: string
): QueryKey => trimRightUndefined([`release-notes-${namespace}`, homepage]);
export const getDictionaryQueryKey = (
  namespace: DictionaryQueryKeyNamespace,
  params?: unknown
): QueryKey => trimRightUndefined([namespace, "dictionary", params]);
