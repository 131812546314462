import { type Dispatch, type ReactNode, type SetStateAction, useMemo, useState } from "react";
import { checkPass, checkPassLength, checkPasswordsMatch } from "util/validators";

import { Provider } from "components/organisms/change_password/provider";

interface Props {
  children: ReactNode;
}

export interface ChangePasswordContext {
  formOk: boolean;
  pass?: string;
  passConfirm?: string;
  setPass: Dispatch<SetStateAction<string | undefined>>;
  setPassConfirm: Dispatch<SetStateAction<string | undefined>>;
}

const ChangePasswordProvider = ({ children }: Props): React.JSX.Element => {
  const [pass, setPass] = useState<string | undefined>();
  const [passConfirm, setPassConfirm] = useState<string | undefined>();

  const formOk = useMemo(() => {
    return (
      !!pass &&
      !!passConfirm &&
      !checkPass(pass) &&
      !checkPassLength(8, pass) &&
      !checkPass(passConfirm) &&
      !checkPasswordsMatch(pass, passConfirm)
    );
  }, [pass, passConfirm]);

  const value = useMemo<ChangePasswordContext>(
    () => ({
      formOk,
      pass,
      passConfirm,
      setPass,
      setPassConfirm,
    }),
    [formOk, pass, passConfirm],
  );

  return <Provider value={value}>{children}</Provider>;
};

export default ChangePasswordProvider;
