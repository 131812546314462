import { type Dispatch, type ReactNode, type SetStateAction, useMemo, useState } from "react";

import { Provider } from "components/organisms/forgot/provider";

interface Props {
  children: ReactNode;
}

export interface ForgotContext {
  formOk: boolean;
  email?: string;
  setEmail: Dispatch<SetStateAction<string | undefined>>;
}

const ForgotProvider = ({ children }: Props): React.JSX.Element => {
  const [email, setEmail] = useState<string | undefined>();

  const formOk = useMemo(() => {
    return !!email;
  }, [email]);

  const value = useMemo<ForgotContext>(
    () => ({
      formOk,
      email,
      setEmail,
    }),
    [formOk, email],
  );

  return <Provider value={value}>{children}</Provider>;
};

export default ForgotProvider;
