import { type FormEventHandler, useCallback } from "react";

import { Button } from "@panel2/systail-ui/button";
import { Input } from "@panel2/systail-ui/form/input";
import { Spinner } from "@panel2/systail-ui/spinner";
import ArrowBack from "components/atoms/icons/ArrowBack";
import Person from "components/atoms/icons/Person";
import { useForgotCtx } from "components/organisms/forgot/provider";
import { useNavigate } from "react-router-dom";

interface Props {
  loading: boolean;
  onSubmit: () => void;
}

const ForgotFormControl = ({ onSubmit, loading }: Props): React.JSX.Element => {
  const { formOk, email, setEmail } = useForgotCtx();
  const navigate = useNavigate();

  const submit = useCallback<FormEventHandler>(
    e => {
      e.preventDefault(); // ! prevent reloading !
      onSubmit();
    },
    [onSubmit],
  );

  return (
    <form
      data-cmp="login:form"
      aria-label="login-form-control"
      onSubmit={submit}
      className="flex flex-col"
    >
      <div className="mt-3 flex">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="username-input" className="me-2 flex">
          <Person size="24px" className="my-auto text-primary" />
        </label>
        <Input
          data-cmp="reset:form:login:input"
          aria-label="username"
          autoComplete="username"
          disabled={loading}
          type="username"
          id="username-input"
          className="border-primary-container"
          value={email ?? ""}
          onChange={({ target }) => setEmail(target.value)}
          placeholder={t`placeholders.enter_email|Enter Email`}
        />
      </div>

      <div className="mt-4 flex justify-between">
        <Button tooltip={t`labels.go_back|Go back`} onClick={() => navigate(-1)}>
          <ArrowBack />
        </Button>
        <Button
          data-cmp="login:form:submit:button"
          aria-label="submit"
          type="submit"
          disabled={!formOk}
          className="mt-auto flex"
          $variant="primary"
        >
          {loading ? (
            <Spinner className="my-auto" />
          ) : (
            <span className="my-auto">{t`labels.submit|Submit`}</span>
          )}
        </Button>
      </div>
    </form>
  );
};

export default ForgotFormControl;
