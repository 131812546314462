import "components/atoms/timeout/style.css";
import clsx from "clsx";
import type { DetailedHTMLProps, HTMLAttributes } from "react";

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  ms: number;
}

const Timeout = ({ className, ms, style, ...rest }: Props): React.JSX.Element => (
  <div
    style={{ animationDuration: `${ms}ms`, ...style }}
    className={clsx("timeout", className)}
    {...rest}
  >
    <span aria-hidden className="invisible">
      timeout
    </span>
  </div>
);

export default Timeout;
