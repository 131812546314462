import {
  type DictionaryQueryKeyNamespace,
  type ItemQueryKeyNamespace,
  type ListQueryKeyNamespace,
  getDictionaryQueryKey,
  getItemQueryKey,
  getListQueryKey,
  getTreeQueryKey,
  isDictionaryQueryKey,
  isItemQueryKey,
  isListQueryKey,
} from "api/queryKeyStore";
import { type ReactNode, useCallback } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { Provider } from "components/hoc/mercure_listener/provider";
import { useCurrentUser } from "components/router/provider";
import useMercureTopic from "hooks/mercure/useMercureTopic";
import { v4 } from "uuid";

interface Props {
  children: ReactNode;
}

export interface MercureUpdateMessage {
  id?: string;
  type: ItemQueryKeyNamespace | ListQueryKeyNamespace | DictionaryQueryKeyNamespace;
  targets: ("item" | "tree" | "list" | "dictionary")[];
}
type OnData = ({
  selfId,
  ...data
}: MercureUpdateMessage & {
  selfId: string;
}) => Promise<void>;
const selfId = v4();

const MercureListener = ({ children }: Props): React.JSX.Element => {
  const queryClient = useQueryClient();
  const user = useCurrentUser();

  const onData = useCallback<OnData>(
    async ({ selfId: sid, ...message }) => {
      if (selfId !== "self" && sid === selfId) {
        // selfId is used to update self immediately
        // ignore loopback message
        return;
      }

      console.info(message, selfId);

      if (typeof message !== "object") {
        console.warn("invalid message: ", message);

        return;
      }

      if (isItemQueryKey(message.type) && message.targets.includes("item")) {
        console.info("invalidating item query: ", getItemQueryKey(message.type, message.id));

        await queryClient.invalidateQueries({
          queryKey: getItemQueryKey(message.type, message.id),
        });
      }

      if (isListQueryKey(message.type)) {
        console.info("invalidating list query: ", getListQueryKey(message.type));
        await queryClient.invalidateQueries({
          queryKey: getListQueryKey(message.type),
        });
      }

      if (isDictionaryQueryKey(message.type) && message.targets.includes("dictionary")) {
        console.info("invalidating dictionary query: ", getDictionaryQueryKey(message.type));
        await queryClient.invalidateQueries({
          queryKey: getDictionaryQueryKey(message.type),
        });
      }

      if (isItemQueryKey(message.type) && message.targets.includes("tree")) {
        console.info("invalidating tree query: ", getTreeQueryKey(message.type, message.id));

        await queryClient.invalidateQueries({
          queryKey: getTreeQueryKey(message.type, message.id),
        });

        await queryClient.invalidateQueries({
          queryKey: getItemQueryKey(message.type, message.id),
        });
      }
    },
    [queryClient],
  );

  const { push } = useMercureTopic<
    MercureUpdateMessage & {
      selfId: string;
    }
  >(`/company/${user.companyId}`, message => onData(message));

  const pushWrapped = useCallback(
    (data: MercureUpdateMessage) => {
      onData({ ...data, selfId: "self" }); // update self immediately
      push({ ...data, selfId }); // update others in the background
    },
    [onData, push],
  );

  return <Provider value={pushWrapped}>{children}</Provider>;
};

export default MercureListener;
