import { BASE_API_URL } from "util/const";
import type { DateTimeFormat } from "model/util";
import type { LoginType } from "components/organisms/login/LoginProvider";
import type { Role } from "model/Permissions";
import { getApi } from "api/common";

interface InternalLoginResponse extends Response {
  loginVariant: "internal";
  domain: string;
  email: string;
  isLdap: false;
  id: string;
  token: string;
  passwordChangeRequired: boolean;
  mercure: {
    token: string;
    expireAt: DateTimeFormat;
  };
  refresh: string;
  company: string;
  roles: Role[];
  companySlug: string;
}

interface LdapLoginResponse extends Response {
  loginVariant: "ldap";
  domain: string;
  email: string;
  isLdap: true;
  id: string;
  token: string;
  refresh: string;
  passwordChangeRequired: boolean;
  mercure: {
    token: string;
    expireAt: DateTimeFormat;
  };
  company: string;
  roles: Role[];
  companySlug: string;
}

interface AzureLoginResponse extends Response {
  loginVariant: "azure";
  redirectUrl: string;
  company: string;
  roles: Role[];
}

type LoginResponse<T extends LoginType = LoginType> = T extends "internal"
  ? InternalLoginResponse
  : T extends "ldap"
    ? LdapLoginResponse
    : T extends "azure"
      ? AzureLoginResponse
      : never;

export const isLoginResponse = <T extends LoginType>(
  response: InternalLoginResponse | LdapLoginResponse | AzureLoginResponse,
  loginVariant: T,
): response is LoginResponse<T> => {
  // response.loginVariant === loginVariant
  if (loginVariant === "ldap") {
    return (
      Object.prototype.hasOwnProperty.call(response, "isLdap") &&
      (response as LdapLoginResponse).isLdap
    );
  }
  if (loginVariant === "azure") {
    return Object.prototype.hasOwnProperty.call(response, "redirectUrl");
  }
  if (loginVariant === "internal") {
    return (
      !Object.prototype.hasOwnProperty.call(response, "isLdap") &&
      !Object.prototype.hasOwnProperty.call(response, "redirectUrl")
    );
  }

  return false;
};

interface LoginRequest {
  username?: string;
  password?: string;
  company?: string;
  domain?: string;
  code?: string;
  state?: string;
  sessionState?: string;
  type: LoginType;
}

interface ResetRequest {
  username: string;
  company: string;
}

interface ResetCheckRequest {
  token: string;
}

export interface ResetCheckResponse {
  valid: boolean;
  email?: string;
}

interface ResetConfirmRequest {
  token: string;
  password: string;
}

export interface CompanyCheckRequest {
  username: string;
  company: string;
}

export interface CompaniesCheckRequest {
  username: string;
}

export interface LoginAzureResponse {
  redirectUrl: string;
}

export const login = (payload: LoginRequest) =>
  payload.type === "azure" && !payload.code
    ? getApi()
        .get<AzureLoginResponse>(`${BASE_API_URL}/auth/companies/${payload.company}/azure`)
        .then(r => r.data)
    : getApi()
        .post<LoginResponse>(`${BASE_API_URL}/auth/${loginTypeEndpoints[payload.type]}`, payload)
        .then(r => r.data);

export const getLoginAzure = (slug: string) =>
  getApi()
    .get<LoginAzureResponse>(`${BASE_API_URL}/auth/companies/${slug}/azure`)
    .then((r) => r.data);

export const companyCheck = (payload: CompanyCheckRequest) => {
  return getApi()
    .post<boolean>(`${BASE_API_URL}/auth/company-check`, payload)
    .then(r => r.data);
};

export const companiesCheck = (payload: CompaniesCheckRequest) => {
  return getApi()
    .post<unknown>(`${BASE_API_URL}/auth/companies`, payload)
    .then(r => r.data);
};

export const reset = (payload: ResetRequest) =>
  getApi()
    .post<unknown>(`${BASE_API_URL}/auth/reset`, payload)
    .then(r => r.data);

export const resetCheck = (payload: ResetCheckRequest) =>
  getApi()
    .post<ResetCheckResponse>(`${BASE_API_URL}/auth/reset-check`, payload)
    .then(r => r.data);

export const resetConfirm = (payload: ResetConfirmRequest) =>
  getApi()
    .post<unknown>(`${BASE_API_URL}/auth/reset-confirm`, payload)
    .then(r => r.data);

const loginTypeEndpoints = {
  internal: "login",
  ldap: "ldap",
  azure: "azure",
};
