import { Link, useNavigate } from "react-router-dom";

import { Button } from "@panel2/systail-ui/button";
import CheckCircle from "components/atoms/icons/CheckCircle";
import Timeout from "components/atoms/timeout/Timeout";
import { useSlug } from "provider";
import { useEffect } from "react";

const TIMEOUT = 20000;

const ForgotInfo = (): React.JSX.Element => {
  const navigate = useNavigate();
  const slug = useSlug();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(`/${slug}/login`);
    }, TIMEOUT);

    return () => clearTimeout(timer);
  }, [navigate, slug]);

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.key === "Enter") navigate(`/${slug}/login`);
    };

    window.addEventListener("keydown", handler);

    return () => window.removeEventListener("keydown", handler);
  }, [navigate, slug]);

  return (
    <div className="flex h-full">
      <div className="relative m-auto flex flex-col overflow-hidden rounded bg-surface-container-high text-on-surface">
        <Timeout className="absolute min-h-full w-full rounded bg-tertiary/[0.2]" ms={TIMEOUT} />

        <div className="flex items-center justify-center p-3 text-xl">
          <strong>
            <span>{t`labels.email_send_successfully|Email send successfully`}</span>
          </strong>

          <CheckCircle className="my-auto ml-2" />
        </div>

        <div className="m-auto flex flex-col p-1 px-4">
          <small>{t`labels.an_email_with_a_link_to_reset_your_password_has_been_send|An email with a link to reset your password has been sent.`}</small>
        </div>

        <div className="m-auto flex flex-col p-1 px-4">
          <small>{t`labels.link_is_valid_for_24h|Link is valid for 24h.`}</small>
        </div>

        <div className="m-auto flex flex-col p-1 px-4">
          <small>{t`labels.please_check_your_mailbox|Please check your mailbox.`}</small>
        </div>

        <Button autoFocus $variant="secondary" className="relative mx-auto my-3">
          <Link className="stretched-link" to={`/${slug}/login`}>
            <span>{t`labels.go_login|Login`}</span>
          </Link>
        </Button>
      </div>
    </div>
  );
};

export default ForgotInfo;
