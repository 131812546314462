import {
  HideProvider,
  NotificationsProvider,
  NotifierProvider,
} from "components/hoc/notifications/provider";
import type Notification from "model/Notification";
import type { NotificationData } from "model/Notification";
import { type ReactNode, useCallback, useRef, useState } from "react";
export type NotificationContext = Notification[];
export type NotifierContext = (notification: NotificationData) => void;

interface Props {
  children: ReactNode;
}

const NotificationProvider = ({ children }: Props): React.JSX.Element => {
  const lastIdRef = useRef<number>(0);
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const notify = useCallback(
    (notification: NotificationData) =>
      setNotifications((old: Notification[]) => [
        ...old,
        {
          id: (++lastIdRef.current).toString(),
          createdAt: new Date(),
          ...notification,
        },
      ]),
    []
  );

  const hide = useCallback(
    (id: string | "all") =>
      setNotifications((old: Notification[]) => {
        if (id === "all") {
          return [];
        }
        return [...old].filter(({ id: theId }) => id.toString() !== theId);
      }),
    []
  );

  return (
    <NotificationsProvider value={notifications}>
      <HideProvider value={hide}>
        <NotifierProvider value={notify as NotifierContext}>
          {children}
        </NotifierProvider>
      </HideProvider>
    </NotificationsProvider>
  );
};

export default NotificationProvider;
